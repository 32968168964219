<template>
    <div :class="'user ' + (linksOpen ? 'open' : '')">

        <div class="avatar">
            <img :src="avatarSrc" />
        </div>

        <div class="info">

            <span class="name" v-html="displayName"></span>

            <div class="integration">

                <div class="integration--item" v-if="discountCodes.length > 0 && availability <= 0 && (!config.guiSettings || !config.guiSettings.discountCodeResidualAsAvailability)">
                    <div class="line">
                        <span v-if="availability < 0">Buono caricato</span>
                    </div>
                    <div class="line">
                        <span class="value">{{discountCodes[0]}}</span>
                    </div>
                </div>

                <div class="integration--item">
                    <div class="line" v-if="availability >= 0">
                        <span v-if="mixinIsWelfare()">Credito Welfare</span>
                        <span v-else>Credito</span>
                        <span class="value">&nbsp;€ {{mixinRoundTwoDigits(availability)}}</span>
                    </div>
                </div>

                <div class="integration--item content">

                    <div class="line" v-if="Object.values(discountCodes).length">
                      Codici sconto: <br/>
                      <ul id="example-1">
                        <li v-for="discountCode in discountCodes" :key="discountCode">
                          {{ discountCode }}
                        </li>
                      </ul>
                      <br/>
                    </div>


                    <div class="line">
                        <a @click="mixinLogout()">Logout</a>
                    </div>
                </div>

            </div>
        </div>

        <div class="toggler" @click="linksToggle">
            <SvgChevronDownToggler />        
        </div>

    </div>
</template>

<script>
    import SvgChevronDownToggler from '@/components/svg/chevron-down-toggler'
    export default {
        name: 'user',
        components:
        {
            SvgChevronDownToggler
        },
        computed:
        {
            discountCodes: {
                get() { return this.$store.state.discountCodes },
                set(value) { this.mixinSendMutation('setDiscountCodes',value) }
            },
            availability: {
                get() {
                    return (this.$store.state.integration ? this.$store.state.integration.availability : '')
                },
                set(value) { this.mixinSendMutation('setAvailabilty',value) }
            },
        },
        data()
        {
            return {
                config: {},
                avatarSrc: '',
                displayName: '',
                linksOpen: false,
            }
        },
        mounted()
        {
            this.config = this.appConfig()
            this.avatarSrc = (this.config.integration ? this.config.integration.avatarSrc : '')
            this.displayName = (this.config.integration ? this.config.integration.displayName : '')

        },
        methods:
        {
            linksToggle(e)
            {
                e.stopPropagation()
                this.linksOpen = !this.linksOpen
                return false
            }
        },
        created()
        {
            window.addEventListener('scroll', () => {
                this.linksOpen = false
            })
            document.addEventListener('click', () => {
                this.linksOpen = false
            })
        },
    }
</script>

<style lang="scss">

    .user
    {
        display:flex;
        align-items: flex-start;
        font-size: 14px;//16px;
        font-weight: 500;
        line-height: 140%;
        .avatar
        {
            width:40px;
            display: flex;

            img {
                height: 100%;
            }
        }
        .info
        {
            padding-left: 10px;
            white-space: nowrap;

            .line
            {
                min-width: 100%;
            }
        }
        .name
        {

        }
        .integration
        {
            &--item
            {
                display:flex;
                flex-wrap: wrap;
            }
            .content
            {
                //border-top:1px solid #dedede;
                //margin-top:10px;
                //padding-top:10px;
                font-size: 14px;


                @include media-breakpoint-down(md) {
                  padding: 15px 0;
                  margin-top: 20px;
                  color: $gray-500;
                  font-weight: 400;
                }
            }
        }
        .value
        {
            @include customize-color("cta");
        }
        .toggler
        {
            display:none;
            
            path
            {
                transition: $transition-base;
                transform-origin:center center;
            }
        }

        @include media-breakpoint-up(lg)
        {
            position:relative;
            align-items: center;

            .content
            {
                display:none;
                position:absolute;
                top:100%;
                right:0;
                margin-top:2rem;
                background:$white;
                padding:$border-radius-lg;
                border-radius:$border-radius-lg;
                li
                {
                    white-space:nowrap;
                }
            }
            .toggler
            {
                display:inline;
            }
            
            &.open
            {
                .toggler .arrow
                {
                    transform:rotate(180deg);
                }
                .content
                {
                    display:block;
                }
            }

        }
    }
</style>