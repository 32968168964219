<template>

    <div class="secondaryLogo" v-if="secondaryLogoSrc != '' && secondaryLogoUrl == ''">
        <img :src="secondaryLogoSrc" />
    </div>
    <div class="secondaryLogo" v-else-if="secondaryLogoSrc != '' && secondaryLogoUrl != ''">
      <a :href="secondaryLogoUrl">
        <img :src="secondaryLogoSrc" />
      </a>
    </div>

</template>

<script>
    export default {
        name: 'secondaryLogo',
        props: {
          secondaryLogoSrc: {
            type: String,
            default: '',
          },
          secondaryLogoUrl: {
            type: String,
            default: '',
          },
        },
    }
</script>

<style lang="scss">
    
    
    

    .secondaryLogo
    {
        height:100%;
        overflow: hidden;
        margin:0 16px;// 0 10px;

        display:flex;
        align-items: center;

        //max-width:140px;
        //padding:0 $grid-gutter-width/2;
        img,svg
        {
            width:auto;
            //max-height:200px;
            max-width:150px;
            max-height: calc(100% - 16px);
        }
    }
</style>