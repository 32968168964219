<template>
    <div :class="'navigation ' + (linksOpen ? 'open' : '')"  @click="linksToggle">
        <div class="navigation-toggle">
            <div class="icon">
                <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.99927 4.00002H25.3993C26.3993 4.00002 27.1992 3.20002 27.1992 2.20002C27.1992 1.20002 26.3993 0.400024 25.3993 0.400024H1.99927C0.999268 0.400024 0.199219 1.20002 0.199219 2.20002C0.199219 3.20002 1.09927 4.00002 1.99927 4.00002Z" fill="#39414E"/>
                    <path d="M1.99927 14.3001H18.6992C19.6992 14.3001 20.4993 13.5001 20.4993 12.5001C20.4993 11.5001 19.6992 10.7001 18.6992 10.7001H1.99927C0.999268 10.7001 0.199219 11.5001 0.199219 12.5001C0.199219 13.5001 1.09927 14.3001 1.99927 14.3001Z" fill="#39414E"/>
                    <path d="M25.4993 21.1H1.99927C0.999268 21.1 0.199219 21.9 0.199219 22.9C0.199219 23.9 0.999268 24.7 1.99927 24.7H25.3993C26.3993 24.7 27.1992 23.9 27.1992 22.9C27.1992 21.9 26.3993 21.1 25.4993 21.1Z" fill="#39414E"/>
                </svg>
            </div>

            <div class="toggler">
                <SvgChevronDownToggler />
            </div>
        </div>

        <!-- navigazione normale VERIFICARE CONDIZIONE V-IF -->
        <ul class="navigation-links" v-if="!mixinIsPublic()">
            <li><router-link :to="{name: 'homepage'}"><span @click="$emit('toggle-menu')">Homepage</span></router-link></li>
            <li><router-link :to="{name: 'blog'}"><span @click="$emit('toggle-menu')">Blog</span></router-link></li>
            <li><router-link :to="{name: 'contacts', hash: '#assistenza'}"><span @click="$emit('toggle-menu')">Contatti</span></router-link></li>
            <li><router-link :to="{name: 'how-it-works', hash: '#come-funziona'}"><span @click="$emit('toggle-menu')">Come funziona</span></router-link></li>
            <li v-if="!mixinIsAuthenticated()"><router-link :to="{name: 'dc-sso'}"><span @click="$emit('toggle-menu')">Ho un Buono</span></router-link></li>
            <li><a href="https://www.salabamsolutions.com" target="_blank">Salabam Solutions</a></li>
            <!--<li><router-link :to="{name: 'covid19'}" class=""><span @click="$emit('toggle-menu')">Covid-19</span></router-link></li>-->
            <!-- TODO 
            <li v-if="etcEnabled && !mixinIsAuthenticated() && !(this.$router.currentRoute.name == 'jp-sso')">
                <router-link :to="{name: 'how-etc-works'}">
                    <SvgEdenredLogoWhite />
                </router-link>
            </li>
            -->
        </ul>

        <!-- navigazione VIVAMOD -->
        <ul class="navigation-links" v-else>
            <li><router-link :to="{name: 'homepage'}"><span @click="$emit('toggle-menu')">Prenota!</span></router-link></li>
            <li><router-link :to="{name: 'dc-sso'}"><span @click="$emit('toggle-menu')">Ho un buono</span></router-link></li>
            <li><router-link :to="{name: 'blog'}"><span @click="$emit('toggle-menu')">Blog</span></router-link></li>
            <!--<li><router-link :to="{name: 'covid19'}" class=""><span @click="$emit('toggle-menu')">Covid-19</span></router-link></li>-->
            <li><router-link :to="{name: 'perche-scegliere-vivamod', hash: '#perche-scegliere-vivamod'}"><span @click="$emit('toggle-menu')">Perchè prenotare con {{site.brand}}</span></router-link></li>
            <router-link :to="{name: 'sistemi-di-pagamento'}"><span @click="$emit('toggle-menu')">Buoni sconto e ticket</span></router-link>
            <li><router-link :to="{name: 'faq'}"><span @click="$emit('toggle-menu')">Domande frequenti</span></router-link></li>
            <li><a href="https://www.salabamsolutions.com" target="_blank"><span @click="$emit('toggle-menu')">Salabam Solutions</span></a></li>
            <li><router-link :to="{name: 'adv'}"><span @click="$emit('toggle-menu')">Adv</span></router-link></li>
            <li><router-link :to="{name: 'about', hash:'#about'}"><span @click="$emit('toggle-menu')">Chi Siamo</span></router-link></li>
            <li><router-link :to="{name: 'contacts', hash: '#assistenza'}"><span @click="$emit('toggle-menu')">Contatti</span></router-link></li>
        </ul>

    </div>
</template>

<script>
    import SvgChevronDownToggler from '@/components/svg/chevron-down-toggler'
    
    export default {
        name: 'navigation',
        emits: ['toggle-menu'],
        components:
        {
            SvgChevronDownToggler,
        },
        data()
        {
            return {
                linksOpen: false,
                site: this.appConfig().site,
                businessVivamodHost: process.env.VUE_APP_BUSINESS_VIVAMOD_HOST,
                isAmbalagi: (process.env.VUE_APP_NAME == 'ambalagi'),
            }
        },
        methods:
        {
            linksToggle(e)
            {
                e.stopPropagation()
                this.linksOpen = !this.linksOpen
                return false
            }
        },
        created()
        {
            window.addEventListener('scroll', () => {
                this.linksOpen = false
            })
            document.addEventListener('click', () => {
                this.linksOpen = false
            })
        },
    }
</script>

<style lang="scss">

    .navigation {
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        position:relative;

        &.open {

          .toggler .arrow {
            transform:rotate(180deg);
          }

          .navigation-links {
            display:block;
          }
        }
      }

      &-links {

        @include media-breakpoint-up(lg) {
          display: none;
          position:absolute;
          top:100%;
          right:0;
          margin-top:2rem;
          background:$white;
          padding:$border-radius-lg;
          border-radius:$border-radius-lg;
        }

        li {
          padding: 13px 0;

          @include media-breakpoint-up(lg) {
            white-space:nowrap;
            padding: 0;
          }
        }
      }

      &-toggle {
          display: none;

          @include media-breakpoint-up(lg) {
            display: flex;
            align-items:center;
          }
      }

      .toggler {

        path {
            transition: $transition-base;
            transform-origin:center center;
        }
      }

      .icon {
        margin-left:10px;

        svg {
            width:auto;
        }
      }


    }
</style>