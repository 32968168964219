<template>
  <div v-if="this.$config.guiSettings.pinnedMessage" class="pinnedMessage">
    <b-alert :variant="variant" show class="pinnedMessageAlert" v-b-popover.hover.top="tooltip">
      <div class="icon"><SvgInfoCircle/></div>
      <span v-html="title"></span>
    </b-alert>
  </div>
</template>


<script>
  import SvgInfoCircle from './../../svg/info-circle'
  export default {
    name: 'pinnedMessage',
    components: {
      SvgInfoCircle,
    },
    data(){
      return{
        title: (this.$config.guiSettings.pinnedMessage)? this.$config.guiSettings.pinnedMessage.title : '',
        tooltip: (this.$config.guiSettings.pinnedMessage)? this.$config.guiSettings.pinnedMessage.tooltip : '',
        variant: (this.$config.guiSettings.pinnedMessage)? this.$config.guiSettings.pinnedMessage.variant : '',
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pinnedMessage
  {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:0px;
    text-align:center;
    width:100%;

    @at-root .page-property & {
      margin-top: 50px;
    }

    .alert
    {
      flex:1;
      width:100%;
      display:flex;
      justify-content: center;
      align-items: center;
      margin-bottom:0px;
      //font-weight: 500;
    }
    .icon
    {
      width:18px;
      margin-right:10px;
    }

  }

</style>