<template>
  <div v-if="poweredByLogo !== false">
    <div class="poweredBy" v-if="poweredByLogo == ''">
        <a href="/" class="wrap" target="_blank">{{poweredByCaption}} <SvgLogoPoweredBy /></a>
    </div>
    <div class="poweredBy" v-else>
      <a href="/" class="wrap" target="_blank">{{poweredByCaption}} <img v-bind:src="poweredByLogo" /></a>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'

  export default {
      name: 'poweredBy',
      components: {
        SvgLogoPoweredBy: () => import('@/components/' + process.env.VUE_APP_CUSTOM_SRC + 'svg/logoPoweredBy'),
      },
      data(){
        return {
          poweredByLogo: (Vue.prototype.$config.guiSettings.poweredByLogo != undefined)? Vue.prototype.$config.guiSettings.poweredByLogo : '',
          poweredByCaption: (Vue.prototype.$config.guiSettings.poweredByCaption)? Vue.prototype.$config.guiSettings.poweredByCaption : 'Powered by',
        }
      }
  }
</script>

<style lang="scss">
    
    
    
    

    .poweredBy
    {
        position:fixed;
        top:50vh;
        left:0;
        padding:2px 24px;
        font-size: 11px;
        background:$white;
        transform-origin:top left;
        transform:rotate(-90deg) translateX(-50%);
        border-bottom-left-radius: 45px;
        border-bottom-right-radius: 45px;
        overflow: hidden;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.25);
        line-height: 1;
        z-index: 2;
        *,*:hover
        {
            text-decoration: none;
            @include customize-color('dark');
        }
        img,svg
        {
            width:auto;
            margin:0 10px !important;
            max-width: 100px !important;
            max-height: 40px;
        }
        .wrap
        {
            display: flex;
            align-items: center;
            padding: 8px 0px;            
        }
    }
</style>