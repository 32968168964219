<template>
    <div id="siteHeader" :class=" (mobileMenuOpen ? 'open' : '') + ' ' + headerSize + (!isVadobay ? ' gradientVivamod' : '')">
        <div v-if="$route.name == 'homepage'" class="pinned">
            <PinnedMessage/>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="brand">

              <svg class="holder" width="380" height="202" viewBox="0 0 380 202" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M250.851 193.7C285.651 184.1 317.651 165.2 341.151 137.8C393.251 77.0999 395.851 -29.0001 329.751 -80.9001C268.051 -129.4 170.551 -119 128.151 -50.4001C104.951 -12.8001 83.851 23.8999 34.751 26.0999C24.751 26.4999 14.651 25.4999 4.65098 26.5999C-49.949 32.6999 4.95097 95.7999 20.651 114.3C56.351 156.3 105.551 187.9 160.151 197.9C190.151 203.4 221.451 201.8 250.851 193.7Z" fill="url(#logo-header-gradient)"/>
                  <defs>
                  <linearGradient id="logo-header-gradient" x1="180.515" y1="197.876" x2="180.515" y2="-110.891" gradientUnits="userSpaceOnUse">
                    <stop class="last-color" offset="0" stop-color="#FFFFFF"/>
                    <stop class="first-color" offset="1" stop-color="#FFFFFF"/>
                  </linearGradient>
                  </defs>
              </svg>

              <div class="image" @click="mixinGoTo('homepage', {})">
                  <SvgLogo v-if="mainLogoSrc == ''"/>
                  <img v-else :src="mainLogoSrc" />
              </div>

          </div>

          <div class="menu">

              <div class="menu--content">

                  <div class="menu--lite">
                    <router-link :to="{name: 'user'}" title="Preferiti e cronologia">
                      <font-awesome-icon icon="heart" />
                      <span @click="toggleMenu()" class="menu--lite-label">Preferiti e cronologia</span>
                    </router-link>
                  </div>

                  <div class="menu--navigation">
                      <Navigation @toggle-menu="toggleMenu" />
                  </div>

                  <!-- ambalagi pubblico gira come "integrato" con anagrafica specifica
                       il mixinIsPublic verifica se si è in modalità "sito pubblico" -->
                  <div class="menu--user" v-if="!mixinIsPublic() && mixinIsAuthenticated()">
                      <User />
                  </div>

                  <div class="menu--secondaryLogo">
                      <SecondaryLogo
                          v-bind:secondaryLogoSrc="customerLogoSrc"
                          v-bind:secondaryLogoUrl="customerLogoUrl"
                      />
                  </div>

              </div>

          </div>

          <div class="toggle" @click="toggleMenu">
              <SvgHamburger />
          </div>

        </div>
        <!-- mostro il poweredBy se viene cambiato il mainLogo oppure definito un logo poweredBy -->
        <div v-if="mainLogoSrc != '' || (poweredByLogo !== false && poweredByLogo != '')" >
          <PoweredBy />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import SecondaryLogo from '@/components/blocks/header/secondary-logo'
    import PoweredBy from '@/components/blocks/common/powered-by'
    import Navigation from '@/components/blocks/header/navigation'
    import User from '@/components/blocks/header/user'
    import SvgHamburger from '@/components/svg/hamburger'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faHeart } from '@fortawesome/free-solid-svg-icons'
    import PinnedMessage from '../../blocks/common/pinnedMessage'
    library.add(faHeart)
    Vue.component('font-awesome-icon', FontAwesomeIcon)

    export default {
        name: 'siteHeader',
        components: {
            SecondaryLogo,
            PoweredBy,
            Navigation,
            User,
            SvgHamburger,
            PinnedMessage,
            SvgLogo: () => import('@/components/' + process.env.VUE_APP_CUSTOM_SRC + 'svg/logo'),

        },
        computed:
        {
            customerLogoSrc: {
              get(){

                // il logo personalizzato può stare sia in config.guiSettings sia in integration
                // quello in integration ha la precedenza
                // quello in guiSettings serve principalmente per salabam sito pubblico
                // per personalizzazioni tramite link
                if ( Vue.prototype.$config.integration && Vue.prototype.$config.integration.customerLogoSrc ) return Vue.prototype.$config.integration.customerLogoSrc;
                if ( Vue.prototype.$config.guiSettings.customerLogoSrc ) return Vue.prototype.$config.guiSettings.customerLogoSrc
                return '';
              },
              set(){},
            },

            customerLogoUrl: {
              get(){

                // il logo personalizzato può stare sia in config.guiSettings sia in integration
                // quello in integration ha la precedenza
                // quello in guiSettings serve principalmente per salabam sito pubblico
                // per personalizzazioni tramite link
                if ( Vue.prototype.$config.integration && Vue.prototype.$config.integration.customerLogoUrl ) return Vue.prototype.$config.integration.customerLogoUrl;
                if ( Vue.prototype.$config.guiSettings.customerLogoUrl ) return Vue.prototype.$config.guiSettings.customerLogoUrl
                return '';
              },
              set(){},
            },

            mainLogoSrc: {
              get(){

                // il logo personalizzato può stare sia in config.guiSettings sia in integration
                // quello in integration ha la precedenza
                // quello in guiSettings serve principalmente per salabam sito pubblico
                // per personalizzazioni tramite link
                if ( Vue.prototype.$config.integration && Vue.prototype.$config.integration.mainLogoSrc ) return Vue.prototype.$config.integration.mainLogoSrc;
                if ( Vue.prototype.$config.guiSettings.mainLogoSrc ) return Vue.prototype.$config.guiSettings.mainLogoSrc
                return '';
              },
              set(){},
            },

        },
        watch: {
            '$route.name': function()
            {
                this.setupHeaderGui()
            }
        },
        data() {
            return {
                headerSize: '',
                mobileMenuOpen: false,
                etcEnabled: Vue.prototype.$config.guiSettings.integrations.etc.enabled,
                isVadobay: (process.env.VUE_APP_NAME == 'vadobay'),
                poweredByLogo: (Vue.prototype.$config.guiSettings.poweredByLogo !== undefined)? Vue.prototype.$config.guiSettings.poweredByLogo : '',
            }
        },
        methods:
        {
            setupHeaderGui()
            {
                this.mobileMenuOpen = false
                this.headerSize = 'large'
                //if(this.$router.currentRoute.name == 'homepage') this.headerSize = 'large'
                //if(this.$router.currentRoute.name == 'in-vadobay') this.headerSize = 'large'
            },
            toggleMenu()
            {
                this.mobileMenuOpen = !this.mobileMenuOpen
                if(this.mobileMenuOpen)
                {
                    document.body.classList.add('menuOpen')
                }
                else
                {
                    document.body.classList.remove('menuOpen')
                }
            },
        },
        mounted()
        {
            this.setupHeaderGui()
        }
    }
</script>

<style lang="scss">

    #siteHeader {
      $header-position: absolute; //fixed

      background-color: $white;
      padding: 10px 0;
      //padding:0 $grid-gutter-width/4;

      @include media-breakpoint-up(lg) {
        background-color: transparent;
        padding: 0;
      }

      &.gradientVivamod {
        @include media-breakpoint-down(md) {
          background: linear-gradient(90deg, var(--custom-gradientCommonFirst) 0%, var(--custom-gradientCommonLast) 100%);
        }
      }

      .brand {
        width:220px;
        position:relative;
        z-index: 9999;

        @include media-breakpoint-down(md) {
          & > svg{
            display: none;
          }
        }

        @include media-breakpoint-up(lg) {
          max-width:400px;//width:400px;
          width:auto;
          height:auto;
          transition:$transition-base;
          transition-delay:0.35s;
        }

        .image {
          width: 60%;
          margin-left: 20px;

          @include media-breakpoint-up(lg) {
            width: 58%;
            position: absolute;
            top: 12%;
            bottom: 25%;
            transform: none;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
              display:block;
              width:100%;
            }

            img {
              width:auto;
              height:auto;
              max-width:100%;
              max-height:100%;
            }
          }
        }

        .image, .image * {
          cursor:pointer;
        }

        .holder {
            position:relative;//absolute;
            width: 100%;
            min-width:380px;
            height: auto;
            top:0;
            left:0;
            @include customize-gradient-svg();

            @include media-breakpoint-up(lg) {
              position:relative;
            }
        }

        img {
            max-height: 100%;
            width:auto;
            margin:0 auto;
        }
      }

      .menu {
        display:none;
        opacity:0;
        transition:all 1s ease-in-out;
        background:#fff;
        height: 100vh;

        &--content {
            height:100%;
            width:100%;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content:center;
        }

        &--secondaryLogo {
            //order:30;
            margin-bottom:40px;
            background:$white;
            // deve mantenere il colore fisso in vadobay e il gradiente in vivamod
            // senza l'if verrebbe usato il gradiente sass in vadobay ed il gradiente --var in vivamod
            @if($customize)
            {
                @include customize-gradient(vertical);
            }
        }

        &--navigation {
            //order:10;
            margin-bottom:20px;
        }

        &--user {
            //order:20;
            margin-bottom:10px;
        }

        &--lite {
            padding: 13px 0;
            text-align: center;

            svg {
              position: relative;
              left: -10px;

              @include media-breakpoint-up(lg) {
                left: 0;
              }
            }

            &-label {

              @include media-breakpoint-up(lg) {
                display: none;
              }
            }

            * {
                color:$liteRed;
            }
        }

        @include media-breakpoint-up(lg) {
            display:flex;
            opacity:1;
            height:auto;

            &--content
            {
                justify-content: flex-end;
            }
        }
      }

      .toggle {
        width:32px;
        height:32px;
        margin-right: 20px;
        position: relative;
        z-index:9999;

        svg {
          width: 100%;
          height: 100%;
        }

        path {
          transition:$transition-base;
          @include customize-fill("dark");

          &:nth-child(1) {
          }
          &:nth-child(2) {
          }
          &:nth-child(3) {
          }
        }

        @include media-breakpoint-up(lg)
        {
            display: none;
        }
      }

      .pinned ~ .toggle {
          top: 40px;
      }

      .pinned ~ .brand .image {
          top: 30px;
      }

      @at-root .page-homepage & .pinned {
        position: relative;
        z-index: 3;

        @include media-breakpoint-up(lg) {
          position: absolute;
          width: 100%;
          z-index: 2;
          top: 0;
        }

        .alert {
            border-radius: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        width:100%;
        position: absolute;
        z-index: 111;

        &:not(.large) {
            height:auto;
            //background-color:$white;

            .menu--item,
            .integration--item.content,
            .navigation-links
            {
                //box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
            }
            //box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);

            .brand
            {
                svg
                {
                    display:none;
                }
                .image
                {
                    transform:none;
                }
                img
                {
                    width:auto;
                    height: 60px;
                    max-height: 100%;
                    margin:$grid-gutter-width/2 0;
                }
            }
        }
        .menu {
            background:transparent;
            width: 100%;

            &--item
            {
                box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.25);
                height:60px;
                display: flex;
                align-items: center;
                padding:$grid-gutter-width/2 $border-radius-lg/3;
                margin-left:$grid-gutter-width;
                border-radius: $border-radius-lg;
            }

            &--content
            {
                flex-direction: row;
                align-items: center;
            }

            &--secondaryLogo
            {
                @extend .menu--item !optional;
                //order:10;
                margin-bottom:0;
                //width:180px;

                //@include theme-gradient-brand();



                padding-left: 80px;
                border-radius: 45px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                height:90px;
                width:auto;

                padding-bottom:0;
                padding-top:0;
            }

            &--navigation
            {
                @extend .menu--item !optional;
                //order:20;
                margin-bottom:0;
                background:$white;
                margin-left: $grid-gutter-width/2;
            }

            &--user
            {
                @extend .menu--item !optional;
                //order:30;
                margin-bottom:0;
                margin-right: -130px;
                z-index: 1;
                background:$white;
                margin-left: 32px;
            }

            &--lite
            {
                padding: 0;
                font-size:28px;
                svg
                {
                    transition:$transition-base;
                }
                &:hover
                {
                    svg
                    {
                        transform:scale(1.2);
                    }
                }
            }

        }

        //transizione per quando la pagina scrolla
        .menu--content,
        .holder,
        .image {
            transition:all 0.5s ease-in-out;
        }
      }

      .toggler {
          margin-left:10px;
          cursor:pointer;
      }

    }


    #siteHeader.open
    {
        @include media-breakpoint-down(lg) {
          .menu {
            display:block;
            opacity: 1;
            top: 0;
            left: 0;
            position: absolute;
            width: 100vw;
            z-index:999;
          }
        }

        .brand {
            //z-index:2;
        }
        .toggle path {

          &:nth-child(2) {opacity:0;}

          &:nth-child(1) {
              transform:rotate(45deg) translateY(-24px) translateX(13px);
          }
          &:nth-child(3) {
              transform:rotate(-45deg) translateY(-7px) translateX(-24px);
          }
        }
    }

    body.menuOpen
    {
        @include media-breakpoint-down(md)
        {
            overflow: hidden;
        }
    }

    /*
    body.scrolling
    {
        @include media-breakpoint-up(lg)
        {
            .menu--item,
            .integration--item.content,
            .navigation-links
            {
                //box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.25);
            }

            #siteHeader
            {
                //.holder,
                //.image
                .brand
                {
                    transform:translateY(-100%);
                    opacity:0;
                }

                .menu--content
                {
                    padding-top:0;
                }

                .menu
                {
                    width:auto;
                    left:auto;
                    right:0;
                }
            }
        }
    }
    */
</style>